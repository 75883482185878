/**
 * Comments which will be loaded from Discourse via our API.
 *
 * @flow
 */
import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import styled from 'styled-components';
import useEventListener from '@use-it/event-listener';

import TextField from '../TextField';
import { Button, LinkLikeButton } from '../../styles';
import Link from '../Link';
import TextStyle from '../TextStyle';
import { getTopicIdFromPermalink, getComments, postComment } from '../../services/comments';
import type { DiscourseComment } from '../../services/comments';
import { getUserData, loginRedirect, prepareLogin } from '../../services/auth';
import LoadingIndicator from '../LoadingIndicator';
import Comment from './Comment';
import type { UserData } from '../../services/auth';

const CommentsWrapper = styled.div`
  margin-top: 70px;

  @media (min-width: 768px) {
    margin-top: 100px;
  }

  @media (min-width: 992px) {
    margin-top: 120px;
  }
`;

const CommentsTitle = styled.h2`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CommentsRespond = styled.div`
  position: relative;
`;

const CommentsLogin = styled.div`
  position: absolute;
  top: 18px;
  left: 18px;
  color: var(--color-text-neutral);
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 5px;
`;

const RespondButtonWrap = styled.div`
  margin-top: 15px;
  text-align: right;
`;

const LoginSignUpButton = styled(LinkLikeButton)`

  &:focus,
  &:hover {

  }
`;

type Props = {
  discoursePermalink: string,
  postTitle: string,
};

const Comments = ({ discoursePermalink, postTitle }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [commentPosts, setCommentPosts] = useState<?Array<DiscourseComment>>(null);
  const [isLoadingLogin, setLoadingLogin] = useState<boolean>(false);
  const [isSubmittingResponse, setSubmittingResponse] = useState<boolean>(false);
  const [showResponseError, setShowResponseError] = useState<boolean>(false);
  const [responseText, setResponseText] = useState<string>('');
  const [userData, setUserData] = useState<?UserData>(getUserData());

  const loginSignUp = useCallback(() => {
    setLoadingLogin(true);
    prepareLogin(loginRedirect, undefined, 'comments');
  }, []);

  const handleResponseChange = useCallback((event) => {
    setResponseText(event.target.value);

    if (showResponseError && event.target.value.length >= 20) {
      setShowResponseError(false);
    }
  }, [showResponseError]);

  const topicId = useMemo(() => getTopicIdFromPermalink(discoursePermalink), [discoursePermalink]);

  const loadComments = useCallback((callback: void | (Array<DiscourseComment>) => void) => {
    if (typeof topicId === 'string') {
      setIsLoading(true);
      getComments(topicId,
        (comments) => {
          setIsLoading(false);
          setCommentPosts(comments);
          if (callback) {
            callback(comments);
          }
        },
        () => {
          setIsLoading(false);
          setFailedToLoad(true);
        });
    } else {
      // Should never happen so long as Discourse permalink URL structure does not change
      setFailedToLoad(true);
    }
  }, []);

  const loadCommentsAndShowLatest = useCallback((callback: ?() => void) => {
    loadComments((comments) => {
      // Show the new comment:
      setTimeout(() => {
        const latestComment = comments[comments.length - 1];
        // We set hash manually because navigate() does not apply :target selector
        window.location.hash = `#response-${latestComment.id}`;
        if (callback) {
          callback();
        }
      }, 200);
    });
  }, []);

  const postNewResponse = useCallback(() => {
    if (responseText.length < 20) {
      setShowResponseError(true);
      return;
    }

    if (typeof topicId === 'string') {
      setSubmittingResponse(true);
      postComment(topicId, null, responseText, () => {
        loadCommentsAndShowLatest(() => {
          setResponseText('');
          setSubmittingResponse(false);
        });
      });
    }
  }, [responseText]);

  useEffect(() => {
    loadComments();
  }, [userData]); // Run only on mount or after login status changes

  useEffect(() => {
    if (commentPosts && window.location.hash && window.location.hash.startsWith('#response-')) {
      // Ensures hash is applied after comments load
      // We set hash manually because navigate() does not apply :target selector
      const { hash } = window.location;
      window.location.hash = '#';
      window.location.hash = hash;
    }
  }, [commentPosts]);

  useEventListener('ethical-logout', () => {
    setUserData(null);
  });

  useEventListener('ethical-login', () => {
    setUserData(getUserData());
  });

  return (
    // comments wrapper id should always be "comments" for compatibility with "Table of Contents"
    <CommentsWrapper id="comments">
      <div className="row">
        <div className="col-md-11 col-lg-8 offset-lg-2 col-xl-6 offset-xl-2">
          <CommentsTitle>
            <>Responses on </>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: postTitle }} />
            ...
          </CommentsTitle>

          <CommentsRespond>
            <StyledTextField
              placeholder={userData ? 'What are you thinking?' : ''}
              multiline={6}
              value={responseText}
              onChange={handleResponseChange}
              disabled={isLoading || !userData}
            />
            {!userData && (
              <CommentsLogin>
                <LoginSignUpButton onClick={loginSignUp}>
                  Login or Sign Up
                </LoginSignUpButton>
                <> to let us know what you think.</>
              </CommentsLogin>
            )}
            {showResponseError && (
              <TextStyle variation="negative">
                Your response is too short!
              </TextStyle>
            )}
            <RespondButtonWrap>
              <Button onClick={postNewResponse} disabled={isLoading || !userData}>Respond!</Button>
            </RespondButtonWrap>
          </CommentsRespond>

          {isLoading && (
            <TextStyle variation="neutral" as="p">
              Loading responses...
            </TextStyle>
          )}
          {failedToLoad && (
            <TextStyle variation="negative" as="p">
              Failed to load responses.
            </TextStyle>
          )}
          {commentPosts && commentPosts.map((post) => !post.replyToPostNumber && (
            <Comment
              key={post.id}
              commentPost={post}
              currentUsername={userData && userData.username}
              loginSignUp={loginSignUp}
              isLoading={isLoading}
              loadCommentsAndShowLatest={loadCommentsAndShowLatest}
              topicId={topicId}
            />
          ))}

          <Link to={discoursePermalink}>
            Join the full discussion our community forum
          </Link>
        </div>
      </div>

      {isSubmittingResponse && <LoadingIndicator message="Submitting your response..." />}
      {isLoadingLogin && <LoadingIndicator message="Connecting to the Ethical Community..." />}
    </CommentsWrapper>
  );
};

export default Comments;
