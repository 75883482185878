// @flow
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import ReadingTime from './ReadingTime';
import { IntroText, postContentBoxCSS } from '../styles';
import PostTitle from './PostTitle';
import ScreenReaderText from './ScreenReaderText';

const Wrapper = styled.div`
  margin-bottom: 25px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 60px;
  }
`;

const TextContentWrapper = styled.div`
  ${postContentBoxCSS}
`;

const PostMeta = styled.div`
  margin: 0px -6px;
  font-size: 12px;
  color: #8e8e8e;

  a {
    box-shadow: none;
    white-space: nowrap;
  }

  @media (min-width: 768px) {
    margin: 0px -10px;
    font-size: 15px;

    .meta-item {
      margin: 10px 10px 20px;
    }
  }

  @media (min-width: 992px) {
    font-size: 16px;

    .meta-item {
      margin: 7px 10px 20px;
    }
  }
`;

const MetaItem = styled.span`
  display: inline-block;
  margin: 5px 6px 12px;

  &:not(:first-of-type) {
    white-space: nowrap;
  }

  @media (min-width: 768px) {
    margin: 10px 10px 20px;
  }

  @media (min-width: 992px) {
    margin: 7px 10px 20px;
  }
`;

const FeaturedImage = styled(Img)`
  margin-top: 25px;

  @media (min-width: 768px) {
    margin-top: 33px;
  }

  @media (min-width: 1200px) {
    margin-top: 53px;
  }
`;

const Caption = styled.figcaption`
  margin-top: 10px;
  color: #7c7c7c;
`;

type Props = {
  title: string,
  author: {
    path: string,
    name: string,
  },
  datePublished: string,
  dateUpdated: string,
  introText?: string,
  featuredImage?: {
    alt: string,
    caption: string,
    localFile?: {
      childImageSharp: {
        fluid: any,
      }
    }
  },
  postContentRef: {
    current: HTMLElement | null,
  },
  isGuide: boolean,
};

const defaultProps = {
  introText: '',
  featuredImage: null,
};

const PostHeader = (props: Props) => {
  const {
    title,
    author,
    datePublished,
    dateUpdated,
    introText,
    featuredImage,
    postContentRef,
    isGuide,
  } = props;

  const [postContentText, setPostContentText] = useState<?string>();

  useEffect(() => {
    /* postContentRef is not set until after render */
    if (postContentRef && postContentRef.current) {
      setPostContentText(postContentRef.current.innerText);
    }
  });

  const postMetaMarkup = !isGuide ? (
    <PostMeta>
      <MetaItem className="author vcard">
        <>Written by </>
        <Link to={author.path} rel="author" className="url">
          {/* eslint-disable-next-line react/no-danger */}
          <span className="fn" dangerouslySetInnerHTML={{ __html: author.name }} />
        </Link>
      </MetaItem>
      <MetaItem as="time" className="published" dateTime={(new Date(datePublished).toISOString())}>{datePublished}</MetaItem>
      <ScreenReaderText>
        <time className="updated" dateTime={(new Date(dateUpdated).toISOString())}>{dateUpdated}</time>
      </ScreenReaderText>
      {postContentText && (
        <MetaItem>
          <ReadingTime text={postContentText} />
        </MetaItem>
      )}
    </PostMeta>
  ) : null;

  return (
    <Wrapper>
      <TextContentWrapper>
        <PostTitle title={title} isGuide={isGuide} />
        {postMetaMarkup}
        {introText && <IntroText>{introText}</IntroText>}
      </TextContentWrapper>
      {featuredImage && featuredImage.localFile && (
        <figure>
          <FeaturedImage fluid={featuredImage.localFile.childImageSharp.fluid} alt={featuredImage.alt || ''} />
          <Caption>{featuredImage.caption}</Caption>
        </figure>
      )}
    </Wrapper>
  );
};

PostHeader.defaultProps = defaultProps;

export default PostHeader;
