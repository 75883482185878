/**
 * A single comment
 *
 * @flow
 */
import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import fromNow from 'fromnow';
import type { DiscourseComment } from '../../services/comments';
import { postComment } from '../../services/comments';
import { AvatarImg, Button, LinkLikeButton } from '../../styles';
import Thanks from './Thanks';
import LoginModal from '../LoginModal';
import Link from '../Link';
import TextField from '../TextField';
import TextStyle from '../TextStyle';
import LoadingIndicator from '../LoadingIndicator';

const CommentInner = styled.div`
`;

const SingleComment = styled.div`
  margin: 1em -1em;
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 4px;

  ${CommentInner}:target > & {
    background-color: rgba(0, 131, 213, 0.08);
  }
`;

const CommentDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 16px;
  width: 100%;
`;

const MiniProfile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1em;
`;

const CommentActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`;

const CommentContent = styled.div`
  width: 100%;
  
  .emoji {
    height: 18px;
    width: 18px
  }
  
  .quote {
    background-color: #f9f9f9;
    padding: 1em 1em 1px 1em;
    border-left: 5px solid #e9e9e9;
    width: 100%;
    margin-bottom: 1em;
    
    .title {
      margin-bottom: 1em;
      
      .avatar {
        border-radius: 50%;
        margin-right: 0.5em;
      }
    }
  }

  p:last-child {
    margin-bottom: 0.5em;
  }
`;

const UsersName = styled.div`
  font-size: 1.2em;
  font-weight: bold;
`;

const PostDate = styled(Link)`
  color: var(--color-text-neutral);
  font-size: 0.9em;
  line-height: 0.9;
  box-shadow: none;

  :hover, :focus {
    text-decoration: none;
    box-shadow: none;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 5px;
`;

const ReplyButtonWrap = styled.div`
  margin-top: 15px;
  text-align: right;
`;

const CommentReplies = styled.div`
  margin-top: 1em;
  margin-left: ${(props) => (props.nestingLevel === 1 ? '64px' : '0')};
  
  ${CommentInner}:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

type Props = {
  commentPost: DiscourseComment,
  loginSignUp: () => void,
  currentUsername: ?string,
  isLoading: boolean,
  loadCommentsAndShowLatest: (?() => void) => void,
  topicId: ?string,
  nestingLevel?: number,
};

const Comment = ({
  commentPost, loginSignUp, currentUsername, isLoading,
  loadCommentsAndShowLatest, topicId, nestingLevel,
}: Props) => {
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showReply, setShowReply] = useState<boolean>(false);
  const [showReplyError, setShowReplyError] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>('');
  const [isSubmittingReply, setSubmittingReply] = useState<boolean>(false);

  const loginSignUpModal = useCallback(() => {
    loginSignUp();
    setShowLoginModal(false);
  }, []);
  const closeModal = useCallback(() => setShowLoginModal(false), []);

  const handleReplyChange = useCallback((event) => {
    setReplyText(event.target.value);

    if (showReplyError && event.target.value.length >= 20) {
      setShowReplyError(false);
    }
  }, [showReplyError]);

  const toggleReply = useCallback(() => {
    if (!currentUsername) {
      setShowLoginModal(true);
      return;
    }

    setShowReply(!showReply);
  }, [currentUsername, showReply]);

  const postNewReply = useCallback(() => {
    if (replyText.length < 20) {
      setShowReplyError(true);
      return;
    }

    if (typeof topicId === 'string') {
      setSubmittingReply(true);
      postComment(topicId, commentPost.postNumber, replyText, () => {
        loadCommentsAndShowLatest(() => {
          setReplyText('');
          setShowReply(false);
          setSubmittingReply(false);
        });
      });
    }
  }, [replyText]);

  const createdAtReadable = useMemo(() => {
    let readable = fromNow(commentPost.createdAt, { max: 1 });
    if (readable !== 'just now') {
      readable += ' ago';
    }
    return readable;
  }, [commentPost]);

  return (
    <CommentInner id={`response-${commentPost.id}`}>
      <SingleComment>
        <AvatarImg src={commentPost.avatarTemplate.replace('{size}', '96')} width="48" height="48" alt="" />
        <CommentDetails>
          <MiniProfile>
            <UsersName>{commentPost.name || commentPost.username}</UsersName>
            <PostDate to={`#response-${commentPost.id}`} title={commentPost.createdAt}>
              {createdAtReadable}
            </PostDate>
          </MiniProfile>
          {/* eslint-disable-next-line react/no-danger */}
          <CommentContent dangerouslySetInnerHTML={{ __html: commentPost.cooked }} />
          <CommentActions>
            <Thanks
              commentPost={commentPost}
              loginSignUp={loginSignUp}
              currentUsername={currentUsername}
            />
            <> </>
            <LinkLikeButton onClick={toggleReply}>Reply</LinkLikeButton>
          </CommentActions>
          {showReply && (
            <>
              <StyledTextField
                placeholder="What are you thinking?"
                multiline={4}
                value={replyText}
                onChange={handleReplyChange}
                disabled={isLoading}
              />
              {showReplyError && (
                <TextStyle variation="negative">
                  Your reply is too short!
                  <> </>
                  If you’d just like to show your gratitude, click “Thanks!”.
                </TextStyle>
              )}
              <ReplyButtonWrap>
                <Button onClick={postNewReply} disabled={isLoading}>Reply!</Button>
              </ReplyButtonWrap>
            </>
          )}
        </CommentDetails>
      </SingleComment>
      {commentPost.replies && (
        <CommentReplies nestingLevel={nestingLevel + 1}>
          {commentPost.replies.map((reply) => (
            <Comment
              key={`${commentPost.id}-${reply.id}`}
              commentPost={reply}
              currentUsername={currentUsername}
              loginSignUp={loginSignUp}
              isLoading={isLoading}
              loadCommentsAndShowLatest={loadCommentsAndShowLatest}
              topicId={topicId}
              nestingLevel={nestingLevel + 1}
            />
          ))}
        </CommentReplies>
      )}

      {isSubmittingReply && <LoadingIndicator message="Submitting your reply..." />}
      {showLoginModal && (
        <LoginModal loginToDoAction="reply" loginSignUp={loginSignUpModal} closeModal={closeModal} />
      )}
    </CommentInner>
  );
};

Comment.defaultProps = {
  nestingLevel: 0,
};

export default Comment;
