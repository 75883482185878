// @flow
import React from 'react';
import { graphql, Link } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import Img from 'gatsby-image';

import type { Meta } from '../types';
import Layout from '../layouts/DefaultLayout';
import SEO from '../components/SEO';
import PostContent from '../components/PostContent';
import RelatedArticleListItem from '../components/RelatedArticleListItem';
import MissingInfoCTA from '../components/MissingInfoCTA';
import HorizontalSlider from '../components/HorizontalSlider';
import { postContentBoxCSS } from '../styles';
import useMediaBreakpoints from '../hooks/useMediaBreakpoints';
import TableOfContents from '../components/TableOfContents';
import PostHeader from '../components/PostHeader';
import Comments from '../components/Comments/Comments';

const GlobalStyle = createGlobalStyle`
  :root {
    --color-background-post-body: ${(props) => (props.isGuide ? 'var(--color-background-guide)' : '#fff')};
  }
`;

const StyledImg = styled(Img)``;

const StyledLayout = styled(Layout)`
  overflow: initial; /* or position: sticky won't work in any of it's descendants */
`;

const Main = styled.main`
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-right: 58px !important;
  }
`;

const AuthorBox = styled.div`
  position: relative;
  margin-top: 40px;
  ${postContentBoxCSS}

  @media (min-width: 768px) {
    display: flex;
    margin-top: 60px;
    align-items: center;
  }

  @media (min-width: 992px) {
    margin-top: 80px;
  }
`;

const AuthorAvatarWrapper = styled.div`
  position: absolute;
  top: 2px;
  left: 0;

  a {
    box-shadow: none !important;
  }

  @media (min-width: 768px) {
    position: static;
  }

  ${StyledImg} {
    width: 39px;
    height: 39px;
    border-radius: 50%;
    overflow: hidden;

    @media (min-width: 768px) {
      width: 94px;
      height: 94px;
      margin-right: 27px;
    }

    @media (min-width: 992px) {
      width: 104px;
      height: 104px;
    }
  }
`;

const AuthorInfo = styled.div`
  p {
    margin-bottom: 0;
  }
`;

const AuthorName = styled.h3`
  margin-left: ${(props) => (props['data-avatar-shown'] ? '53px' : 0)};
  font-size: 13px;
  font-weight: normal;
  font-family: var(--font-family-main);
  color: #7c7c7c;

  > span {
    display: block;
  }

  a {
    font-family: var(--font-family-heading);
    font-size: 21px;
    line-height: 1.2;
    font-weight: 500;
    box-shadow: none;
  }

  @media (min-width: 768px) {
    margin-left: 0;
    font-size: 16px;

    a {
      font-size: 24px;
    }
  }
`;

const StyledMissingInfoCTA = styled(MissingInfoCTA)`
  margin: 26px 0 40px;
`;

const RelatedArticles = styled.div`
  margin: 70px 0;

  @media (min-width: 768px) {
    margin: 80px 0;
  }

  @media (min-width: 992px) {
    margin: 120px 0;
  }
`;

type Props = {
  data: {
    post: {
      title: string,
      content: string,
      datePublished: string,
      dateUpdated: string,
      type: string,
      yoastMeta: Meta[],
      customMeta: {
        title: string,
        canonical: string,
      },
      meta: {
        discourse_permalink: string,
      },
      featuredImage?: {
        alt: string,
        caption: string,
        localFile?: {
          childImageSharp: {
            fluid: any,
          },
        },
      },
      author: {
        name: string,
        path: string,
        description: string,
        acf?: {
          avatar?: {
            alt: string,
            localFile?: {
              childImageSharp: {
                fluid: any,
              },
            },
          },
        },
      },
      acf?: {
        introText?: string,
        hideTOC: boolean,
        relatedArticles?: Array<{
          id: string,
          path: string,
          title: string,
          excerpt: string,
          categories?: Array<{
            name: string,
          }>,
          featuredImage?: {
            alt: string,
            localFile?: {
              childImageSharp: {
                fluid: any,
              },
            },
          },
        }>,
      },
    },
  },
};

const PostTemplate = (props: Props) => {
  const {
    data: {
      post,
    },
  } = props;

  const isGuide = post.type === 'guide';

  const postContentRef = React.createRef<HTMLElement>();
  const { isDesktop } = useMediaBreakpoints();

  const authorAvatar = post.author.acf && post.author.acf.avatar;
  const authorBoxMarkup = post.author ? (
    <AuthorBox>
      {authorAvatar && authorAvatar.localFile && (
        <AuthorAvatarWrapper>
          <Link to={post.author.path} rel="author">
            <StyledImg
              fluid={authorAvatar.localFile.childImageSharp.fluid}
              alt={authorAvatar.alt || ''}
            />
          </Link>
        </AuthorAvatarWrapper>
      )}
      <AuthorInfo>
        <AuthorName data-avatar-shown={!!authorAvatar}>
          <span>Written by </span>
          <Link to={post.author.path} rel="author">
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: post.author.name }} />
          </Link>
        </AuthorName>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: post.author.description }} />
      </AuthorInfo>
    </AuthorBox>
  ) : null;

  const hideTOC = post.acf && post.acf.hideTOC;

  const sidebarMarkup = !hideTOC && isDesktop ? (
    <aside className="col-lg-4 col-xl-3 offset-xl-1">
      <TableOfContents postContentRef={postContentRef} />
    </aside>
  ) : null;

  const missingInfoCTAMarkup = <StyledMissingInfoCTA />;

  const relatedArticlesMarkup = post.acf && post.acf.relatedArticles ? (
    <RelatedArticles>
      <HorizontalSlider
        title="Related Articles"
        subtitle="There is so much more to learn, dive in!!"
      >
        {post.acf.relatedArticles.map((item) => (
          <RelatedArticleListItem
            key={item.id}
            item={item}
          />
        ))}
      </HorizontalSlider>
    </RelatedArticles>
  ) : null;

  return (
    <StyledLayout isGuide={isGuide} backgroundColor={isGuide ? 'var(--color-background-guide)' : undefined}>
      <GlobalStyle isGuide={isGuide} />
      <SEO
        title={post.customMeta.title}
        canonical={post.customMeta.canonical}
        meta={post.yoastMeta}
      />
      <div className="row">
        <Main className="col-lg-8">
          <article className="hentry">
            <PostHeader
              title={post.title}
              author={post.author}
              datePublished={post.datePublished}
              dateUpdated={post.dateUpdated}
              featuredImage={post.featuredImage}
              introText={post.acf && post.acf.introText}
              postContentRef={postContentRef}
              isGuide={post.type === 'guide'}
            />
            <PostContent
              content={post.content}
              postType={post.type}
              ref={postContentRef}
            />
          </article>
          <aside>
            {authorBoxMarkup}
          </aside>
        </Main>
        {sidebarMarkup}
      </div>
      {!isDesktop && missingInfoCTAMarkup}
      {relatedArticlesMarkup}
      {post.meta && post.meta.discourse_permalink && (
        <Comments
          postTitle={post.title}
          discoursePermalink={post.meta.discourse_permalink}
        />
      )}
    </StyledLayout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    post: wordpressWpPostsAndGuides(id: { eq: $id }) {
      title
      content
      type
      datePublished: date(formatString: "MMMM D, YYYY")
      dateUpdated: modified(formatString: "MMMM D, YYYY")
      yoastMeta: yoast_meta {
        name
        property
        content
      }
      customMeta: et_custom_meta {
        title
        canonical
      }
      meta {
        discourse_permalink
      }
      featuredImage: featured_media {
        alt: alt_text
        caption
        localFile {
          childImageSharp {
            fluid(maxWidth: 848) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      author {
        name
        path
        description
        acf {
          avatar {
            alt: alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 104) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      acf {
        introText: intro_text
        hideTOC: hide_table_of_contents
        relatedArticles: related_articles {
          id
          path
          title
          excerpt
          categories {
            name
          }
          featuredImage: featured_media {
            alt: alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 310) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
