/**
 * Prompts the user to login after attempting a user action that requires login.
 * The user can select "Login / Sign Up" to be directed to the Discourse login screen.
 * Alternatively the user can dismiss the modal.
 *
 * @flow
 */
import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import {
  OverlayPanel, LightboxOuter, LightboxInner, Button, CancelButton,
} from '../styles';

const LightboxHeader = styled.div`
  background-color: #FCD9EA;
  height: 120px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`;

const LightboxContent = styled.div`
  padding: 2em;
  
  p {
    margin-bottom: 0;
    font-size: 1.2em;
  }
`;

const LoginSignUpButton = styled(Button)`
  margin-top: 2em;
  padding-left: 38px;
  padding-right: 38px;

  &:focus,
  &:hover {
    color: #fff;
    background: #000;
    border-color: #000;
  }
`;

const StyledCancelButton = styled(CancelButton)`
  position: absolute;
  top: -10px;
  right: -15px;
`;

const StyledImg = styled(Img)`
  width: 300px;
  max-width: 100%;
  margin: auto;
`;

type Props = {
  closeModal: () => void,
  loginSignUp: () => void,
  // Action the user is trying to do, eg. reply, give feedback:
  loginToDoAction: string,
  data: {
    illustrationHighFive: {
      childImageSharp: {
        fluid: any,
      },
    },
  }
}

const LoginModal = ({
  closeModal, loginSignUp, loginToDoAction, data: { illustrationHighFive },
}: Props) => (
  <>
    <OverlayPanel />
    <LightboxOuter onClick={closeModal}>
      <LightboxInner onClick={(event) => event.stopPropagation()}>
        <LightboxHeader>
          <StyledCancelButton onClick={closeModal} />
          <StyledImg
            fluid={illustrationHighFive.childImageSharp.fluid}
            alt=""
            loading="eager"
          />
        </LightboxHeader>
        <LightboxContent>
          <h3>
            <>Login to </>
            {loginToDoAction}
          </h3>
          <p>We are a community of people who love life.</p>
          <p>Don’t forget to breath!</p>
          <LoginSignUpButton onClick={loginSignUp}>Login / Sign Up</LoginSignUpButton>
        </LightboxContent>
      </LightboxInner>
    </LightboxOuter>
  </>
);

export default ({ closeModal, loginSignUp, loginToDoAction }: {
  closeModal: () => void,
  loginSignUp: () => void,
  loginToDoAction: string,
}) => (
  <StaticQuery
    query={graphql`
      query {
        illustrationHighFive: file(relativePath: { eq: "illustration-highfive.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => (
      <LoginModal
        data={data}
        closeModal={closeModal}
        loginSignUp={loginSignUp}
        loginToDoAction={loginToDoAction}
      />
    )}
  />
);
