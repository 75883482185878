/**
 * Thanks button (equivalent to 'like')
 *
 * @flow
 */
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import type { DiscourseComment } from '../../services/comments';
import { LinkLikeButton } from '../../styles';
import { likeComment, unlikeComment } from '../../services/comments';
import LoginModal from '../LoginModal';

const ThanksInner = styled.div`
  display: inline-block;
  padding: 6px;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 3px;
  
  button, button:hover {
    color: ${(props) => (props.selected ? ' var(--color-button-decoration)' : '')};
  }
`;

const LikeCount = styled.span`
  color: var(${(props) => (props.selected ? '--color-button-decoration' : '--color-text-neutral')});
  font-weight: 500;
  font-size: 0.8em;
  display: inline-block;
  min-width: 12px;
  text-align: center;
  padding-left: 4px;
`;

type Props = {
  commentPost: DiscourseComment,
  currentUsername: ?string,
  loginSignUp: () => void,
};

const Thanks = ({ commentPost, currentUsername, loginSignUp }: Props) => {
  const [likeCount, setLikeCount] = useState<number>(commentPost.likeCount);
  const [isLikedByUser, setLikedByUser] = useState<boolean>(commentPost.liked);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  /*
   * We use `wasPreviouslyLikedByUser` to remember if the comment was initially liked on page load.
   * If so we block the user from un-liking a comment. This is a simplification of Discourse
   * functionality, where a post cannot be un-liked after X minutes.
   */
  const [wasPreviouslyLikedByUser] = useState<boolean>(commentPost.liked);

  useEffect(() => {
    setLikeCount(commentPost.likeCount);
    setLikedByUser(commentPost.liked);
  }, [commentPost]);

  const loginSignUpModal = useCallback(() => {
    loginSignUp();
    setShowLoginModal(false);
  }, []);

  const toggleLiked = useCallback(() => {
    if (!currentUsername) {
      setShowLoginModal(true);
      return;
    }

    setLikeCount(likeCount + (isLikedByUser ? -1 : 1));
    if (isLikedByUser) {
      unlikeComment(commentPost.id, () => {});
    } else {
      likeComment(commentPost.id, () => {});
    }
    // We assume that liking/un-liking will succeed
    // If something goes wrong this is not a significant error.
    setLikedByUser(!isLikedByUser);
  }, [currentUsername, likeCount, isLikedByUser]);

  const likeButtonTitle = isLikedByUser ? 'Unlike this comment' : 'Like this comment';

  return (currentUsername === commentPost.username && likeCount === 0) ? <></> : (
    <ThanksInner selected={isLikedByUser}>
      <>🙏 </>
      {currentUsername !== commentPost.username && (
        <LinkLikeButton
          onClick={toggleLiked}
          selected={isLikedByUser}
          disabled={wasPreviouslyLikedByUser}
          title={wasPreviouslyLikedByUser ? 'You\'ve liked this comment' : likeButtonTitle}
        >
          Thanks
        </LinkLikeButton>
      )}
      <LikeCount selected={isLikedByUser}>{likeCount}</LikeCount>

      {showLoginModal && (
        <LoginModal loginToDoAction="say thanks" loginSignUp={loginSignUpModal} closeModal={() => setShowLoginModal(false)} />
      )}
    </ThanksInner>
  );
};

export default Thanks;
