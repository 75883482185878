// @flow
import React from 'react';
import Helmet from 'react-helmet';

import { Wrapper } from './PostContent.styles';

type Props = {
  content: string,
  postType: 'post' | 'page' | 'guide',
};

const PostContent = React.forwardRef<Props, any>(({ content, postType }: Props, ref) => (
  <>
    <Helmet>
      {/*
        We download the latest version of following styles
        using Gatsby Node API `onPreBootstrap`
        @see gatsby-node.js
      */}
      <link
        key="wp-block-library-style"
        rel="stylesheet"
        href="/wp-block-library.css"
      />
      <link
        key="wp-atomic-blocks-style"
        rel="stylesheet"
        href="/wp-atomic-blocks.css"
      />
      <link
        key="wp-ethical-blocks-style"
        rel="stylesheet"
        href="/wp-ethical-blocks.css"
      />
    </Helmet>
    <Wrapper
      ref={ref}
      className="entry-content"
      dangerouslySetInnerHTML={{ __html: content }}
      data-post-type={postType}
    />
  </>
));

export default PostContent;
