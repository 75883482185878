/**
 * Exports functions related to comments.
 * They communicate with our API as necessary.
 *
 * @flow
 */

import { apiUrl } from '../../config';
import { getJson, postJson } from '../utils';

export type DiscourseComment = {
  id: number,
  postNumber: number,
  name: string,
  username: string,
  avatarTemplate: string,
  createdAt: string,
  cooked: string,
  replyCount: number,
  replyToPostNumber: ?number,
  liked: boolean,
  likeCount: number,
  // `replies` will only be set when returned by nestCommentReplies(...)
  replies?: Array<DiscourseComment>
};

/**
 * Returns a copy of a comments array with replies nested.
 *
 * @param comments - original flat array of comments without nested replies, will not be changed
 * @returns {Array<DiscourseComment>} Copy of array with replies nested. Comments which are replies
 * are still included at the root level but can easily be filtered out by checking `replyCount`
 */
export const nestCommentReplies = (comments: Array<DiscourseComment>): Array<DiscourseComment> => {
  // Duplicate to avoid mutating original Array
  // Since this is a shallow copy, `replies` will not be properly duplicated...
  // but `replies` should not be set and will be overwritten in this function anyway
  const nestedComments: Array<DiscourseComment> = comments.map((c) => ({ ...c }));

  nestedComments.forEach((comment) => {
    // Attach any replies to the comment
    // We already created new comment objects above so safe to reassign:
    // eslint-disable-next-line no-param-reassign
    comment.replies = comment.replyCount
      ? nestedComments.filter((c) => c.replyToPostNumber === comment.postNumber)
      : undefined;
  });

  return nestedComments;
};

export const getComments = (
  topicId: string,
  successCallback: (Array<DiscourseComment>) => void,
  errorCallback: () => void,
) => {
  // If user is logged in we fetch comments on behalf of that user to get their "liked" statuses
  const sso = window.localStorage.getItem('sso');
  const sig = window.localStorage.getItem('sig');
  const authParams = (sso && sig) ? `&sso=${encodeURIComponent(sso)}&sig=${encodeURIComponent(sig)}` : '';
  getJson(`${apiUrl}/comments?topic=${topicId}${authParams}`,
    (data) => successCallback(nestCommentReplies(data.comments)), errorCallback);
};

export const likeComment = (
  postId: number,
  successCallback: () => void,
  errorCallback: () => void = () => {},
) => {
  const sso = window.localStorage.getItem('sso');
  const sig = window.localStorage.getItem('sig');
  postJson(`${apiUrl}/like`, {
    sso, sig, post_id: postId,
  }, successCallback, errorCallback);
};

export const unlikeComment = (
  postId: number,
  successCallback: () => void,
  errorCallback: () => void = () => {},
) => {
  const sso = window.localStorage.getItem('sso');
  const sig = window.localStorage.getItem('sig');
  postJson(`${apiUrl}/unlike`, {
    sso, sig, post_id: postId,
  }, successCallback, errorCallback);
};

export const postComment = (
  topicId: string,
  replyToPostNumber: ?number,
  postContent: string,
  successCallback: () => void,
  errorCallback: () => void = () => {},
) => {
  const sso = window.localStorage.getItem('sso');
  const sig = window.localStorage.getItem('sig');
  postJson(`${apiUrl}/post`, {
    sso,
    sig,
    topic_id: topicId,
    reply_to_post_number: replyToPostNumber,
    post_content: postContent,
  }, successCallback, errorCallback);
};

export const getTopicIdFromPermalink = (permalink: string): ?string => {
  const matches = permalink.match(/\/([0-9]+)$/);
  return matches && matches.length === 2 ? matches[1] : null;
};
