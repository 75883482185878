// @flow
import * as React from 'react';
import readingTime from 'reading-time';

type Props = {
  text: string,
  className?: string,
  as?: React.ComponentType<any> | string,
};

const defaultProps = {
  className: undefined,
  as: 'span',
};

const ReadingTime = ({
  text,
  className,
  as: Component = 'span',
}: Props) => {
  const [readingTimeText, setReadingTimeText] = React.useState<string | null>(null);

  React.useEffect(() => {
    const stats = readingTime(text);
    setReadingTimeText(stats.text);
  }, []);

  return readingTimeText && (
    <Component className={className}>{readingTimeText}</Component>
  );
};

ReadingTime.defaultProps = defaultProps;

export default ReadingTime;
