import styled, { css } from 'styled-components';
import {
  postContentBoxCSS,
  pageContentBoxCSS,
} from '../../styles';

function getPrimaryColor(postType) {
  let color;
  switch (postType) {
    case 'post':
      color = '#4a90e2';
      break;

    case 'guide':
      color = '#26985f';
      break;

    case 'page':
      color = '#4a4a4a';
      break;

    default:
      color = '#4a4a4a';
      break;
  }

  return color;
}

const wpBlocksCustomStyle = css`
  figure figcaption {
    font-size: 13px;
    font-family: var(--font-family-main);
    color: #7c7c7c;
    text-align: left;
  }

  .wp-block-code {
    font-size: 0.9em;
    line-height: 1.4;
    padding: .8em 1em;
    border: 1px solid #e2e4e7;
    border-radius: 4px;
  }

  .wp-block-pullquote {
    border-top: 4px solid #555d66;
    border-bottom: 4px solid #555d66;
    color: #40464d;
  }

  .wp-block-table {
    width: 100%;
    min-width: 240px;

    td {
      padding: 0.5em;
    }
  }

  .wp-block-separator {
    border: none;
    border-bottom: 2px solid #8e8e8e;
    margin-left: auto !important;
    margin-right: auto !important;

    &.is-style-wide {
      border-bottom-width: 1px;
    }

    &.is-style-dots {
      background: none;
      border: none;
      text-align: center;
      max-width: none;
      line-height: 1;
      height: auto;

      &::before {
        content: "\\00b7 \\00b7 \\00b7";
        color: #8e8e8e;
        font-size: 20px;
        letter-spacing: 2em;
        padding-left: 2em;
        font-family: serif;
      }
    }

    &:not(.is-style-wide):not(.is-style-dots) {
      max-width: 100px;
    }
  }

  ul.wp-block-rss {
    margin: 0;
    padding: 0 !important;

    li + li {
      margin-top: 32px;
    }

    li::before {
      content: none !important;
    }
  }

  @media (min-width: 768px) {
    figure figcaption {
      font-size: 16px;
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  font-size: ${(props) => (props['data-post-type'] === 'page' ? '16px' : '14px')};
  line-height: 1.8;
  font-family: ${(props) => (props['data-post-type'] === 'page' ? 'var(--font-family-main)' : 'var(--font-family-article-body)')};

  @media (min-width: 768px) {
    font-size: ${(props) => (props['data-post-type'] === 'page' ? '19px' : '17px')};
  }

  && > * {
    ${(props) => (props['data-post-type'] === 'page' ? pageContentBoxCSS : postContentBoxCSS)}

    &.alignwide,
    &.alignfull {
      position: relative;
      z-index: 101; /* so that it stays on the top of the table of contents */
      width: calc(100vw - var(--container-gutter-width) * 2);
      max-width: var(--max-content-width-without-gutter);
    }

    &.wp-block-image:not(figure) {
      max-width: none;

      figure {
        margin: 0;
        float: none;

        &.aligncenter {
          ${(props) => (props['data-post-type'] === 'page' ? pageContentBoxCSS : postContentBoxCSS)}
        }

        &.alignright {
          position: relative;
          z-index: 101; /* so that it stays on the top of the table of contents */
          width: calc(100vw - var(--container-gutter-width) * 2);
          max-width: var(--max-content-width-without-gutter);

          img {
            width: 100%; /* scale a bit to fit */
          }

          img,
          figcaption {
            display: block;
            /* max-width = post content width + sidebar width including offset + gutter */
            max-width: calc(var(--max-post-content-width) + (var(--max-content-width) - var(--container-gutter-width)) * 1/3);
            margin-left: auto;
            margin-right: 0;
          }
        }
      }
    }
  }

  > *[class^="wp-block-"]:not(.wp-block-spacer):not(.wp-block-ethical-blocks-step) {
    margin-top: 32px;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  ${wpBlocksCustomStyle}

  ul:not(.wp-block-gallery),
  ol {
    list-style: none;
    padding-left: 30px;
    margin-bottom: 24px;

    ul,
    ol {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 5px;

      &::before {
        content: "\\2022";
        display: inline-block;
        width: 18px;
        margin-left: -18px;
        color: ${(props) => getPrimaryColor(props['data-post-type'])};
        transform: scale(1.6);
      }
    }

    @media (min-width: 768px) {
      padding-left: 46px;
      margin-bottom: 42px;

      li::before {
        width: 28px;
        margin-left: -28px;
      }
    }
  }

  ol {
    counter-reset: ordered-list-item-number;

    li {
      counter-increment: ordered-list-item-number;

      ::before {
        content: counter(ordered-list-item-number) ". ";
        transform: none;
        vertical-align: middle;
      }
    }
  }
`;
