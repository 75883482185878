// @flow
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { useThrottleCallback } from '@react-hook/throttle';
import scrollIntoView from 'scroll-into-view-if-needed';

let headingCount = 1;
function uniqueElementId(prefix: string = 'et'): string {
  const id = `${prefix}-${headingCount}`;
  headingCount += 1;
  return id;
}

const STICKY_OFFSET = 0;

const StickyWrapper = styled.div`
  position: sticky;
  top: ${STICKY_OFFSET}px;
  z-index: 100;
  display: flex;
  flex-flow: column;
  max-height: 100vh;
  padding: 20px 0 20px 20px;
  background: var(--color-background-post-body);
  border-radius: 0 0 0 4px;

  h3 {
    flex-shrink: 0;
  }

  &::after {
    content: '';
    display: block;
    max-width: 174px;
    height: 1px;
    flex: 0 0 1px;
    margin-top: 28px;
    background: #c3c3c3;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  flex-shrink: 1;

  li + li {
    margin-top: 5px;
  }

  a {
    color: #8e8e8e;
    box-shadow: none;
  }

  li[data-active="true"] {
    a {
      color: var(--color-text);
    }
  }

  a:focus,
  a:hover {
    color: var(--color-text-primary);
    box-shadow: none;
  }
`;

type TOCItemObj = {
  text: string,
  targetId: string,
};

type Props = {
  postContentRef: any,
  className?: string,
};

const defaultProps = {
  className: undefined,
};

const TableOfContents = (props: Props) => {
  const { className, postContentRef } = props;

  const [tocItems, setTOCItems] = useState<?TOCItemObj[]>();
  const [activeItem, setActiveItem] = useState<?TOCItemObj>();

  const wrapperRef = useRef<?HTMLDivElement>();
  const listRef = useRef<?HTMLUListElement>();

  useEffect(() => {
    if (!postContentRef.current) {
      return;
    }

    const items = [];
    let headingElements = postContentRef.current.querySelectorAll('.entry-content > h2');

    // if there are no h2 tags then let's build table of contents from h3 tags
    headingElements = headingElements.length === 0
      ? postContentRef.current.querySelectorAll('.entry-content > h3')
      : headingElements;

    // if there are no h3 tags then let's try h4
    headingElements = headingElements.length === 0
      ? postContentRef.current.querySelectorAll('.entry-content > h4')
      : headingElements;

    // return early if there are no h4 tags as well
    if (headingElements.length === 0) {
      return;
    }

    items.push({
      text: 'Introduction',
      targetId: 'content',
    });

    const prosConsBlockWrapper = postContentRef.current.querySelector('.wp-block-ethical-blocks-pros-cons');

    if (prosConsBlockWrapper) {
      if (!prosConsBlockWrapper.id) {
        prosConsBlockWrapper.id = uniqueElementId('et-pros-cons');
      }

      items.push({
        text: 'Pros and cons',
        targetId: prosConsBlockWrapper.id,
      });
    }

    // Note: ignoring nested h2 elements for now but this can be modified later as required
    headingElements.forEach((el: HTMLHeadingElement) => {
      const headingText = el.innerText ? el.innerText.trim() : null;

      if (!headingText) {
        return;
      }

      if (!el.id) {
        // Eslint: false positive, this is how we set the id property of a HTML element
        // eslint-disable-next-line no-param-reassign
        el.id = uniqueElementId('et-heading');
      }

      items.push({
        text: headingText,
        targetId: el.id,
      });
    });

    items.push({
      text: 'Responses',
      targetId: 'comments',
    });

    setTOCItems(items);
    setActiveItem(items[0]);
  }, []);

  const updateActiveItem = useCallback(() => {
    if (!tocItems) {
      return;
    }

    let newActiveItem;

    const viewportHeight = document.documentElement
      ? document.documentElement.clientHeight
      : window.innerHeight;

    function getTargetBounding(targetId) {
      const targetEl = document.getElementById(targetId);
      if (!targetEl) {
        return false;
      }

      return targetEl.getBoundingClientRect();
    }

    // select the first item whose target element is in the upper two third of the viewport
    newActiveItem = tocItems.find((item) => {
      const targetBoundingRect = getTargetBounding(item.targetId);
      if (!targetBoundingRect) {
        return false;
      }

      const { top } = targetBoundingRect;
      return top > 0 && top < (viewportHeight * (2 / 3));
    });

    // if there is no such item then select the last item
    // whose target element is hidden (due to scrolling) above the viewport
    newActiveItem = newActiveItem || tocItems.slice().reverse().find((item) => {
      const targetBoundingRect = getTargetBounding(item.targetId);
      if (!targetBoundingRect) {
        return false;
      }

      const { top } = targetBoundingRect;
      return top < 0;
    });

    if (newActiveItem) {
      setActiveItem(newActiveItem);
    }
  }, [tocItems]);

  /**
   * Scrolls the list item into the view if needed
   *
   * It's important to make sure that the wrapper element is in the "stuck" state
   * or it will conflict with the wrapper's sticky behavior
   */
  const scrollItemIntoView = useCallback(() => {
    if (!wrapperRef.current) {
      return;
    }

    const { top: wrapperTop } = wrapperRef.current.getBoundingClientRect();
    const setIsSticky = wrapperTop === STICKY_OFFSET;

    if (setIsSticky) {
      const activeItemEl = listRef.current
        && listRef.current.querySelector('li[data-active="true"]');

      if (!activeItemEl) {
        return;
      }

      scrollIntoView(activeItemEl, {
        scrollMode: 'if-needed',
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  }, []);

  const handleScrollThrottled = useThrottleCallback(() => {
    updateActiveItem();
    scrollItemIntoView();
  }, 30, true);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollThrottled);
    return () => {
      window.removeEventListener('scroll', handleScrollThrottled);
    };
  }, [tocItems]);

  function handleItemClick(e: SyntheticEvent<HTMLAnchorElement>) {
    try {
      const href = e.currentTarget.getAttribute('href');

      if (!href) {
        return;
      }

      const targetId = href.replace(/^#/, '');
      const targetEl = document.getElementById(targetId);

      if (!targetEl) {
        return;
      }

      targetEl.scrollIntoView();

      // prevent default behavior only if we are able to scroll to target element using JS
      e.preventDefault();
    } catch {
      // do nothing, default scroll behavior will work
    }
  }

  return tocItems ? (
    <StickyWrapper
      className={className}
      ref={wrapperRef}
    >
      <h3>In this article</h3>
      <List ref={listRef}>
        {tocItems.map((item) => (
          <li key={item.targetId} data-active={item === activeItem}>
            <a href={`#${item.targetId}`} onClick={handleItemClick} data-target-id={item.targetId}>
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: item.text }} />
            </a>
          </li>
        ))}
      </List>
    </StickyWrapper>
  ) : null;
};

TableOfContents.defaultProps = defaultProps;

export default TableOfContents;
